<template>
  <nav class="navbar bg-dark-shadow" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" href="/">
        <h1 class="is-size-4 has-text-weight-light">Moto Kandemir Vespa Servis</h1>
      </a>
      <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" @click="isMenuActive = !isMenuActive">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div class="navbar-menu" v-bind:class="{ 'is-active': isMenuActive }">
      <div class="navbar-end">
        <!-- <router-link class="navbar-item" to="/" @click.native="isMenuActive = false">
          <i class="las la-home "></i>
          <span class="text">Anasayfa</span>
        </router-link> -->

        <router-link class="navbar-item" to="/about" @click.native="isMenuActive = false">
          <i class="las la-info-circle"></i>
          <span class="text">Hakkımızda</span>
        </router-link>

        <router-link class="navbar-item" to="/service" @click.native="isMenuActive = false">
          <i class="las la-tools"></i>
          <span class="text">Teknik Servis</span>
        </router-link>

        <router-link class="navbar-item" to="/contact" @click.native="isMenuActive = false">
          <i class="las la-phone"></i>
          <span class="text">İletişim</span>
        </router-link>

        <a class="navbar-item" :href="url.instagram" target="_blank" aria-label="moto kandemir instagram">
          <i class="lab la-instagram la-lg"></i>
          <span class="text">Galeri</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<style scoped>
a>span.text {
  padding-left: 4px;
}

@media screen and (max-width: 1023px) {
  .navbar-menu {
    background-color: rgba(0, 0, 0, 0.725);
    position: absolute;
    right: 0;
  }

  .navbar-menu a {
    margin: 6px 0;
  }
}
</style>

<script>
import appData from '../app-data';

export default {
  name: 'NavBar',
  data: function () {
    return {
      isMenuActive: false,
      url: appData.pageData.contact.url
    };
  }
};
</script>
