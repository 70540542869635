const appData = {
  bgImages: [
    './assets/bg1.jpg', './assets/bg2.jpg', './assets/bg3.jpg', './assets/bg4.jpg', './assets/bg5.jpg'
  ],
  bgInterval: 0,
  title: 'Moto Kandemir',
  pageData: {
    contact: {
      name: 'Moto Kandemir Vespa Servis',
      address: 'Acibadem, Asaf Bey Sokağı, No:3, Kadıköy/İstanbul, 34718',
      email: `vespaservis@${window.location.host}`,
      mobile: [
        '0535 342 0320',
        '0535 300 4913'
      ],
      landline: '0216 346 26 99',
      url: {
        instagram: 'https://instagram.com/motokandemir',
        facebook: 'https://www.facebook.com/groups/464039863631695'
      }
    }
  }

};

export default appData;
