<template>
  <footer class="footer">
    <ul class="ab ab-list">
      <li>
        <a :href="url.instagram" target="_blank" aria-label="moto kandemir instagram">
          <i class="lab la-instagram la-2x"></i>
        </a>
      </li>
      <li>
        <a :href="url.facebook" target="_blank" aria-label="moto kandemir facebook">
          <i class="lab la-facebook-f la-2x"></i>
        </a>
      </li>
      <li>
        <p class="fp">moto kandemir servis - vespa, honda, yamaha, harley davidson, kawasaki, bmw, ktm, scooter, motosiklet servisi - istanbul, kadikoy</p>
      </li>
    </ul>
    <!-- <a :href="`tel:+9${landline.replace(/\s/g, '')}`">
      <img class="ab ab-service" alt="motokandemir vespa servis" src="../assets/service.gif" />
    </a> -->
  </footer>
</template>

<style scoped>
.footer {
  padding: 10px;
  position: fixed;
  width: 100%;
  bottom: 0px;
  background: none;
}

a,
li {
  padding: 10px;
}

.ab {
  position: absolute;
  bottom: 0;
}

.ab-list {
  left: 0;
}

.ab-service {
  right: 0;
  width: 100px;
}
</style>

<script>
// @ is an alias to /src
import appData from '../app-data';

export default {
  name: 'FooterBar',
  data: function () {
    return appData.pageData.contact;
  }
};
</script>
