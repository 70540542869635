<template>
  <div class="contact py-4 px-2">
    <section class="hero bg-dark-shadow is-medium py-6 px-4">
      <div class="hero-body">
        <div class="container">
          <p class="title is-4">{{ name }}</p>
          <p class="subtitle mt-1">{{ address }}</p>
          <p class="my-2">
            <a href="https://maps.app.goo.gl/i9Xi8gVagGD3mkRs5" target="_blank" rel="noopener">
              <i class="las la-map-marked-alt la-lg"></i>
              <span class="px-2">Konum göster</span>
            </a>
          </p>

          <p class="divider py-4"></p>

          <p class="mt-4">
            <a :href="`mailto:${email}}`">
              <i class="las la-envelope la-lg"></i>
              <span class="px-2">{{ email }}</span>
            </a>
          </p>

          <p class="mt-4">
            <i class="las la-phone la-lg"></i>
            <span class="px-2"><a class="mr-4" v-for="m in mobile" :key="m" :href="`tel:+9${m.replace(/\s/g, '')}`">{{ m }}</a> </span>
          </p>

          <p class="mt-4">
            <i class="lab la-whatsapp la-lg"></i>
            <span class="px-2"><a class="mr-4" v-for="m in mobile" :key="m" :href="`https://wa.me/9${m.replace(/\s/g, '')}`">{{ m }}</a> </span>
          </p>

          <p class="mt-4">
            <i class="las la-tty la-lg"></i>
            <span class="px-2"><a :href="`tel:+9${landline.replace(/\s/g, '')}`">{{ landline }}</a></span>
          </p>

          <!-- <p class="mt-4">
            <a class="mr-4" :href="url.facebook" target="_blank" aria-label="moto kandemir facebook">
              <i class="lab la-facebook-f la-lg"></i>
            </a>
            <a class="mr-4" :href="url.instagram" target="_blank" aria-label="moto kandemir instagram">
              <i class="lab la-instagram la-lg"></i>
            </a>
          </p> -->

        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.title {
  font-weight: normal;
}

.subtitle {
  line-height: 1.7rem;
}

.divider {
  border-bottom: 1px solid gray;
}
</style>

<script>
// @ is an alias to /src
import appData from '../app-data';

export default {
  name: 'Contact',
  data: function () {
    return appData.pageData.contact;
  }
};
</script>
