<template>
  <div id="app" class="bg-hero">
    <nav-bar></nav-bar>

    <div class="tile is-ancestor">
      <div class="tile is-vertical is-3">
        <div class="tile is-parent is-vertical">
          <div class="container">
            <img class="brand-logo" alt="motokandemir logo" src="./assets/motokandemir_white.png" width="400" height="260" />
          </div>
        </div>
      </div>
      <div class="tile is-parent">
        <div class="container">
          <router-view />
        </div>
      </div>
    </div>

    <footer-bar></footer-bar>
  </div>
</template>

<style>
html {
  overflow: hidden;
  overflow-x: hidden;
}

html,
body,
div#app {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1f1f1f;
  text-shadow: 0px 0px 6px #2d2d2d;
}

.bg-hero {
  background-image: url('./assets/bg5.jpg');
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

h1,
h2,
h3,
div,
p,
a,
body {
  color: #efefef !important;
}

.bg-dark-shadow {
  background-color: #00000088;
}

section.bg-dark-shadow {
  border-bottom-left-radius: 64px;
  box-shadow: 0px 8px 38px rgba(255, 255, 255, 0.36),
    0px 8px 12px rgba(255, 255, 255, 0.25);
  z-index: 106;
  position: relative;
}

a,
a:hover {
  background: none !important;
  border-bottom: 2px solid transparent !important;
}

a:hover {
  border-bottom: 2px solid #efefef !important;
}

img.brand-logo {
  filter: drop-shadow(2px 10px 8px black);
}
</style>

<script>
// @ is an alias to /src
import NavBar from './components/NavBar';
import FooterBar from './components/FooterBar';
import appData from './app-data';

// const _heroBg = document.querySelector('.bg-hero');

export default {
  name: 'App',
  components: {
    NavBar,
    FooterBar
  }
};
</script>
