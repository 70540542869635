<template>
  <div class="service py-4 px-2">
    <section class="hero bg-dark-shadow is-large py-6 px-4">
      <div class="container has-text-centered py-6">
        <figure class="image is-256x256">
          <img src="../assets/texa_diag.png" />
        </figure>
      </div>

      <div class="container has-text-centered">
        <div class="columns py-5">
          <div class="column">
            <div class="level">
              <div class="level-item">
                <figure class="rounded">
                  <i class="las la-tools las la-4x"></i>
                </figure>
              </div>
            </div>
            <p class="subtitle is-4">TAMİR VE BAKIM</p>
            <p>
              Siz çayınızı kahvenizi içerken motorunuzun bakımlarını uzman
              teknik kadromuzla eksiksiz şekilde yapalım.
            </p>
          </div>

          <div class="column">
            <div class="level">
              <div class="level-item">
                <figure class="rounded">
                  <i class="las la-life-ring las la-4x"></i>
                </figure>
              </div>
            </div>
            <p class="subtitle is-4">YOL YARDIM</p>
            <p>
              En zor anınızda Moto Kandemir 7/24 yol yardım her zaman yanınızda
              <br />
              <router-link to="/contact">
                <i class="las la-phone"></i>
                <span class="text"> İletişim</span>
              </router-link>
            </p>
          </div>

          <div class="column">
            <div class="level">
              <div class="level-item">
                <figure class="rounded">
                  <i class="las la-drafting-compass la-4x"></i>
                </figure>
              </div>
            </div>
            <p class="subtitle is-4">SİZE ÖZEL TASARIMLAR</p>
            <p>
              Siz isteyin, biz yapalım.
              <br />60 lı yılların efsane akımı geri döndü. Size özel tasarımlar
              motosikletinizi zevkinize göre yeniden tasarlayalım.
            </p>
          </div>

          <div class="column">
            <div class="level">
              <div class="level-item">
                <figure class="rounded">
                  <i class="las la-box las la-4x"></i>
                </figure>
              </div>
            </div>
            <p class="subtitle is-4">ORJİNAL</p>
            <p>
              Moto Kandemir, motosikletinizdeki parçaların orjinal ve fabrika
              çıkışlı olmasına özen gösterir; sizi yarı yolda bırakmaz.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
figure.rounded {
  background-color: #e000009e;
  border-radius: 66px;
  padding: 20px;
}
</style>
