<template>
  <div class="about py-4 px-2">
    <section class="hero bg-dark-shadow py-6 px-4">
      <div class="hero-body">
        <div class="container">
          <p class="title is-3">Tutkunuz – Tutkumuz</p>
          <p class="subtitle mt-1">
            Firmamız sektörde 40 yılı aşkın deneyimiyle ve koşulsuz müşteri memnuniyeti ilkesiyle, tamir ve bakım hizmeti vermektedir.
          </p>
          <p class="subtitle mt-1">
            Moto Kandemir Vespa Servis
          </p>
          <p>
            Vespa, Honda, Piaggio, Yamaha, Harley Davidson, Kawasaki, Suzuki, BMW, KTM, Scooter, Ducati, SYM, Agusta, Aprilia, Triumph, Benelli
          </p>
          <p>
            Istanbul, Kadikoy
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.title {
  font-weight: normal;
}

.subtitle {
  line-height: 1.7rem;
}
</style>
